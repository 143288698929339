import React, { useState, useEffect, useCallback } from "react";
import {
  IconButton,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Portal,
  Collapse,
  useMediaQuery
} from "utils/material-ui-core";
import RHCloseIcon from "icon-close";
import { useQuery } from "@apollo/client";
import { queryAboveNavPromo } from "graphql-client/queries/above-nav-promo";
import {
  BREAKPOINT_XS,
  BREAKPOINT_SM,
  BREAKPOINT_MD,
  BREAKPOINT_LG,
  BREAKPOINT_XL,
  RH_ALERT_COOKIE_NAME,
  ALERT_COOKIE_VALUE,
  ALERT_COOKIE_DEFAULT_HOUR
} from "utils/constants";
import { RHReferenceableComponentProps } from "types";
import { RHAnimatableComponentProps } from "utils/animation/types";
import { useAnimation } from "utils/animation/useAnimation";
import { getContent, TextContent } from "component-text";
import he from "he";
import memoize from "utils/memoize";
import { splitFont } from "aem/ui/authoring/rh/FontPicker2";
import { useCookies } from "hooks/useCookies";
import moment from "moment";
import { TailwindSkeleton as Skeleton } from "component-tailwind-skeletons";
import loadable from "@loadable/component";
import { processEnvServer } from "hooks/useSsrHooks";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import useSite from "hooks/useSite";
import { useCountry } from "hooks/useCountry";

const AEM_COOKIE_VALUE = "cookieStorage";
const AEM_SESSION_VALUE = "sessionStorage";
const SPEED = 900;
const TIME_DURATION = "2000";
const FADE_ANIMATION = "fadeInAndOut";
const INITIAL_SLIDE = 0;

const Slider = loadable(() => import("react-slick"), {
  fallback: (
    <div style={{ height: "750px" }}>
      <Skeleton variant="rect" height={750} />
    </div>
  )
});
interface RHMultifieldMessage {
  text?: string;
}

export type AlertProps = React.HTMLAttributes<HTMLDivElement> &
  RHAnimatableComponentProps &
  RHReferenceableComponentProps & {
    textColorXS?: string;
    textColorSM?: string;
    textColorMD?: string;
    textColorLG?: string;
    textColorXL?: string;
    alignmentXS?: string;
    alignmentSM?: string;
    alignmentMD?: string;
    alignmentLG?: string;
    alignmentXL?: string;
    lineHeightXS?: string;
    lineHeightSM?: string;
    lineHeightMD?: string;
    lineHeightLG?: string;
    lineHeightXL?: string;
    styleXS?: string;
    styleSM?: string;
    styleMD?: string;
    styleLG?: string;
    styleXL?: string;
    transformXS?: string;
    transformSM?: string;
    transformMD?: string;
    transformLG?: string;
    transformXL?: string;
    fontXS?: string;
    fontSM?: string;
    fontMD?: string;
    fontLG?: string;
    fontXL?: string;
    spacingXS?: string;
    spacingSM?: string;
    spacingMD?: string;
    spacingLG?: string;
    spacingXL?: string;
    sizeXS?: string;
    sizeSM?: string;
    sizeMD?: string;
    sizeLG?: string;
    sizeXL?: string;
    hideXS?: boolean;
    hideSM?: boolean;
    hideMD?: boolean;
    hideLG?: boolean;
    hideXL?: boolean;
    textXS?: TextContent | Array<RHMultifieldMessage>;
    textSM?: TextContent | Array<RHMultifieldMessage>;
    textMD?: TextContent | Array<RHMultifieldMessage>;
    textLG?: TextContent | Array<RHMultifieldMessage>;
    textXL?: TextContent | Array<RHMultifieldMessage>;
    closeIconColor?: string;
    backgroundColor?: string;
    linkURL?: string;
    sessionCookie?: string;
    cookieStoreHours?: string;
    animation?: string;
    timeDuration?: string;
  };

const useStyles = (props: AlertProps) =>
  makeStyles((theme: Theme) => {
    const styles: any = {};
    const [fontXS, styleXS, weightXS] = splitFont(props?.fontXS);
    const [fontSM, styleSM, weightSM] = splitFont(props?.fontSM);
    const [fontMD, styleMD, weightMD] = splitFont(props?.fontMD);
    const [fontLG, styleLG, weightLG] = splitFont(props?.fontLG);
    const [fontXL, styleXL, weightXL] = splitFont(props?.fontXL);
    if (props.textXS) {
      styles[theme.breakpoints?.up("xs")] = {
        fontFamily: fontXS,
        lineHeight: props.lineHeightXS,
        fontStyle: styleXS,
        fontWeight: weightXS,
        textTransform: props.transformXS,
        textAlign: props.alignmentXS,
        color: props.textColorXS,
        fontSize: props.sizeXS,
        letterSpacing: props.spacingXS
      };
    }
    if (props.textSM) {
      styles[theme.breakpoints?.up("sm")] = {
        fontFamily: fontSM,
        lineHeight: props.lineHeightSM,
        fontStyle: styleSM,
        fontWeight: weightSM,
        textTransform: props.transformSM,
        textAlign: props.alignmentSM,
        color: props.textColorSM,
        fontSize: props.sizeSM,
        letterSpacing: props.spacingSM
      };
    }
    if (props.textMD) {
      styles[theme.breakpoints?.up("md")] = {
        fontFamily: fontMD,
        lineHeight: props.lineHeightMD,
        fontStyle: styleMD,
        fontWeight: weightMD,
        textTransform: props.transformMD,
        textAlign: props.alignmentMD,
        color: props.textColorMD,
        fontSize: props.sizeMD,
        letterSpacing: props.spacingMD
      };
    }
    if (props.textLG) {
      styles[theme.breakpoints?.up("lg")] = {
        fontFamily: fontLG,
        lineHeight: props.lineHeightLG,
        fontStyle: styleLG,
        fontWeight: weightLG,
        textTransform: props.transformLG,
        textAlign: props.alignmentLG,
        color: props.textColorLG,
        fontSize: props.sizeLG,
        letterSpacing: props.spacingLG
      };
    }
    if (props.textXL) {
      styles[theme.breakpoints?.up("xl")] = {
        fontFamily: fontXL,
        lineHeight: props.lineHeightXL,
        fontStyle: styleXL,
        fontWeight: weightXL,
        textTransform: props.transformXL,
        textAlign: props.alignmentXL,
        color: props.textColorXL,
        fontSize: props.sizeXL,
        letterSpacing: props.spacingXL
      };
    }
    return createStyles({
      closeIcon: {
        fontSize: 12,
        [theme.breakpoints?.up("sm")]: {
          fontSize: 16
        }
      },
      closeButton: {
        position: "absolute",
        right: "1%",
        width: "40px",
        color: props.closeIconColor
      },
      content: {
        "&>div": { paddingRight: "40px" },
        position: "relative",
        width: "100%",
        [theme.breakpoints?.up(BREAKPOINT_XS)]:
          styles[theme.breakpoints?.up("xs")],
        [theme.breakpoints?.up(BREAKPOINT_SM)]:
          styles[theme.breakpoints.up("sm")],
        [theme.breakpoints?.up(BREAKPOINT_MD)]:
          styles[theme.breakpoints?.up("md")],
        [theme.breakpoints?.up(BREAKPOINT_LG)]:
          styles[theme.breakpoints.up("lg")],
        [theme.breakpoints?.up(BREAKPOINT_XL)]:
          styles[theme.breakpoints?.up("xl")],
        "& .rhtext--content-mobile-hide": {
          [theme.breakpoints?.down(BREAKPOINT_SM)]: {
            display: "none"
          }
        },
        "& .rhtext--content-tablet-hide": {
          [theme.breakpoints?.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
            display: "none"
          }
        },
        "& .rhtext--content-desktop-hide": {
          [theme.breakpoints?.up(BREAKPOINT_MD)]: {
            display: "none"
          }
        }
      },
      root: {
        // We cannot use "display: none" as it cripples the exit animation.
        left: -10000,
        position: "absolute",
        top: "auto",
        visibility: "hidden",
        "&:last-child": {
          // As part of SITES-649, we are using CSS to keep only a single eyebrow during the alert lifetime.
          position: "initial",
          top: "unset",
          left: "unset",
          visibility: "initial"
        }
      },
      container: {
        position: "relative",
        alignItems: "center",
        display: "flex",
        width: "100%",
        backgroundColor: props.backgroundColor
      },
      slickSlider: {
        display: "block",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "100%"
      }
    });
  });

export const RHAlert: React.FC<AlertProps> = ({
  extraHTMLAttributes = {},
  rhUid,
  rhAnimation,
  ...props
}) => {
  useAnimation({ rhUid, rhAnimation });
  const classes = useStyles(props)();
  const upXS = useMediaQuery<Theme>(theme => theme.breakpoints?.down("sm"));
  const upSM = useMediaQuery<Theme>(theme => theme.breakpoints?.up("sm"));
  const upMD = useMediaQuery<Theme>(theme => theme.breakpoints?.up("md"));
  const upLG = useMediaQuery<Theme>(theme => theme.breakpoints?.up("lg"));
  const upXL = useMediaQuery<Theme>(theme => theme.breakpoints?.up("xl"));
  const {
    textXS,
    textSM,
    textMD,
    textLG,
    textXL,
    hideXS,
    hideSM,
    hideMD,
    hideLG,
    hideXL,
    sessionCookie,
    animation,
    timeDuration
  } = props;

  const siteId = useSite();
  const [open, setOpen] = useState(true);
  const [root, setRoot] = useState<HTMLElement | null>(null);
  const [cookies] = useCookies([RH_ALERT_COOKIE_NAME]);
  const { setCookieWrapper, setStorageValueWrapper } =
    useCookiesWithPermission();
  const [alertState, setAlertState] = useState(() => {
    const stored = !processEnvServer
      ? sessionStorage.getItem(RH_ALERT_COOKIE_NAME)
      : false;
    return stored ? stored : "";
  });
  const isCookieStorage = sessionCookie === AEM_COOKIE_VALUE ? true : false;
  const isSessionStorage = processEnvServer
    ? false
    : sessionCookie === AEM_SESSION_VALUE || !isCookieStorage
    ? true
    : false;
  const cookieStoreHours = props.cookieStoreHours
    ? parseInt(props.cookieStoreHours)
    : ALERT_COOKIE_DEFAULT_HOUR;
  const country = useCountry();

  const { data: { aboveNavPromo } = {} as Query } = useQuery<Query>(
    queryAboveNavPromo,
    {
      variables: {
        siteId,
        deviceType: "mobile",
        currencyCode: "USA",
        countryCode: country
      }
    }
  );

  useEffect(() => {
    setRoot(document.getElementById("rh-alert-banner"));
  }, [root]);

  useEffect(() => {
    if (sessionCookie === AEM_COOKIE_VALUE) {
      if (cookies?.RH_ALERT === ALERT_COOKIE_VALUE) {
        setOpen(false);
      }
    } else if (sessionCookie === AEM_SESSION_VALUE) {
      if (alertState === ALERT_COOKIE_VALUE) {
        setOpen(false);
      }
    }
  }, [open]);
  /*if (cookies[RH_ALERT_COOKIE_NAME] === ALERT_COOKIE_VALUE) {
    return null;
  }*/

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [false]);

  const handleCleanUp = useCallback(() => {
    if (isCookieStorage) {
      const hours = moment().add(cookieStoreHours, "hour").format();
      setCookieWrapper(RH_ALERT_COOKIE_NAME, ALERT_COOKIE_VALUE, {
        expires: new Date(hours)
      });
    } else if (isSessionStorage) {
      setAlertState(ALERT_COOKIE_VALUE);
      setStorageValueWrapper({
        storageKey: RH_ALERT_COOKIE_NAME,
        value: ALERT_COOKIE_VALUE
      });
    }
  }, [
    isCookieStorage,
    isSessionStorage,
    cookieStoreHours,
    setCookieWrapper,
    setStorageValueWrapper
  ]);

  const text = upXL
    ? hideXL
      ? null
      : textXL
      ? textXL
      : textLG
      ? textLG
      : textMD
      ? textMD
      : textSM
      ? textSM
      : textXS || ""
    : upLG
    ? hideLG
      ? null
      : textLG
      ? textLG
      : textMD
      ? textMD
      : textSM
      ? textSM
      : textXS || ""
    : upMD
    ? hideMD
      ? null
      : textMD
      ? textMD
      : textSM
      ? textSM
      : textXS || ""
    : upSM
    ? hideSM
      ? null
      : textSM
      ? textSM
      : textXS || ""
    : hideXS
    ? null
    : textXS || "";

  const navItemsList =
    text &&
    Array.isArray(text) &&
    text.map(item => {
      return (
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{
            __html: he.decode(getContent(item.text))
          }}
        />
      );
    });
  if (
    (isSessionStorage && alertState === ALERT_COOKIE_VALUE) ||
    (isCookieStorage && cookies?.[RH_ALERT_COOKIE_NAME])
  ) {
    return null;
    //sessionStorage.setItem(RH_ALERT_COOKIE_NAME, ALERT_COOKIE_VALUE);
  }

  return (
    <Portal container={() => root}>
      <Collapse className={classes.root} in={open} onExited={handleCleanUp}>
        {(navItemsList && navItemsList.length) || (text && text.length) ? (
          <Typography
            className={classes.container}
            component={"div"}
            id={"component-rh-alert"}
          >
            {navItemsList ? (
              <Slider
                draggable={true}
                autoplay={true}
                autoplaySpeed={Number(timeDuration)}
                easing="linear"
                className={classes.slickSlider}
                infinite={true}
                fade={animation === FADE_ANIMATION ? true : false}
                lazyLoad="progressive"
                initialSlide={INITIAL_SLIDE}
                cssEase={"ease-in-out"}
                touchThreshold={100}
                arrows={false}
                speed={SPEED}
              >
                {navItemsList}
              </Slider>
            ) : (
              text &&
              typeof text == "string" && (
                <div
                  className={classes.content}
                  dangerouslySetInnerHTML={{
                    __html: he.decode(getContent(text))
                  }}
                />
              )
            )}
            <IconButton
              classes={{ root: classes.closeButton }}
              data-above-nav-close="close-nav-button"
              onClick={handleClose}
            >
              <RHCloseIcon className={classes.closeIcon} />
            </IconButton>
          </Typography>
        ) : null}
      </Collapse>
    </Portal>
  );
};

RHAlert.defaultProps = {
  timeDuration: TIME_DURATION
};

export default memoize(RHAlert);
